import React, { Component } from "react";
import Slider from "react-slick";
import SliderVideo from "./SliderVideo";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ep5 from "../assets/img/episodes/ep5.png";
import ep6 from "../assets/img/episodes/ep6.png";
import ep7 from "../assets/img/episodes/ep7.png";
import ep8 from "../assets/img/episodes/ep8.png";
import ep9 from "../assets/img/episodes/ep9.png";
import ep10 from "../assets/img/episodes/ep10.png";
import ScriptTag from "react-script-tag";

function Previousbtn(props) {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <i className="fa fa-angle-left"></i>
    </div>
  );
}
function Nextbtn(props) {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <i className="ml-2 fa fa-angle-right"></i>
    </div>
  );
}

export default class Premiere extends Component {
  render() {
    const settings = {
      nextArrow: <Nextbtn />,
      prevArrow: <Previousbtn />,
      slidesToShow: 3,
      responsive: [
        {
          breakpoint: 1025,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 769,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 501,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    };
    return (
      <div className="font-family-1 top-site">
        <div
          style={{
            paddingTop: "220px",
            margin: "auto",
            display: "flex",
            justifyContent: "center",
          }}
        ></div>
        <div
          className="d-lg-flex d-none"
          style={{
            height: "400px",
            width: "auto",
            justifyContent: "center",
          }}
        >
          <iframe
            src="https://www.youtube.com/embed/5kgPZ0iTSgs"
            frameborder="2"
            allow="autoplay; encrypted-media"
            allowfullscreen
            title="video"
            width="640px"
            height="360px"
          />
        </div>
        <div
          className="d-flex d-lg-none"
          style={{
            justifyContent: "center",
          }}
        >
          <iframe
            src="https://www.youtube.com/embed/5kgPZ0iTSgs"
            frameborder="2"
            allow="autoplay; fullscreen; encrypted-media"
            allowfullscreen
            title="video"
            width="480px"
            height="250px"
          />
        </div>
        <div className="text-center mt-4">
          <h4>PREMIERING</h4>
          <h2>SEPTEMBER 23, 2021</h2>
          <div className="font-family-2 text-white">
            Available On Our Official YouTube Channel
          </div>
          <div className="d-flex justify-content-center align-items-center m-4">
            <a
              className="watchbtn d-flex justify-content-center align-items-center"
              href="https://www.youtube.com/watch?v=4JY-Eo6Ad3w&list=PLy4KWtJrf3Uo1oLmh8MgAOJFNRcO-LQPL"
              target="_blank"
            >
              <i
                className="fab fa-youtube fa-3x"
                style={{ color: "white", marginRight: "10px" }}
              ></i>
              WATCH NOW
            </a>
          </div>
        </div>

        <div style={{ position: "relative" }}>
          <hr
            id="event-anchor"
            style={{
              position: "absolute",
              top: "80px",
              left: "0",
            }}
          />
          <hr
            id="episode"
            style={{
              position: "absolute",
              top: "-200px",
              left: "0",
            }}
          />
          <Slider className="episode-slider d-block" {...settings}>
            <SliderVideo videoUrl="https://www.youtube.com/embed/4JY-Eo6Ad3w" />
            <SliderVideo videoUrl="https://www.youtube.com/embed/hRgnz5cxE1A" />
            <SliderVideo videoUrl="https://www.youtube.com/embed/yHlRuNEe1A8" />
            <SliderVideo videoUrl="https://www.youtube.com/embed/Sujr4B1nt0c" />
            <SliderVideo videoUrl="https://www.youtube.com/embed/YZV9RnB-uXk" />
            <SliderVideo videoUrl="https://www.youtube.com/embed/lBYpYlUk7QY" />
            <SliderVideo videoUrl="https://www.youtube.com/embed/beNKVlME6O8" />
            <SliderVideo videoUrl="https://www.youtube.com/embed/ucvz60ilGxk" />
            <SliderVideo videoUrl="https://www.youtube.com/embed/S17krT3hCfw" />
            <SliderVideo videoUrl="https://www.youtube.com/embed/yEuXlaXdWhs" />
          </Slider>
        </div>
      </div>
    );
  }
}
