import Cimg1 from "../assets/img/logo frame.png";
import Cimg2 from "../assets/img/logo frame (1).png";
import Cimg3 from "../assets/img/logo frame (2).png";
import Cimg4 from "../assets/img/logo frame (3).png";
import Cimg5 from "../assets/img/logo frame (4).png";
import Cimg6 from "../assets/img/logo frame (5).png";
import Cimg7 from "../assets/img/logo frame (7).png";
import Cimg8 from "../assets/img/logo frame (8).png";
import Cimg9 from "../assets/img/logo frame (9).png";
import Cimg10 from "../assets/img/logo frame (10).png";

// Thumbnails for players
import pimg0 from "../assets/img/Thumbnails/BusinessMan/Paul-Phua.png";
import pimg1 from "../assets/img/Thumbnails/BusinessMan/Richard-Yong.png";
import pimg2 from "../assets/img/Thumbnails/BusinessMan/Stanley-Choi.png";
import pimg3 from "../assets/img/Thumbnails/BusinessMan/WaiKin-Yong.png";
import pimg4 from "../assets/img/Thumbnails/BusinessMan/Bobby-Baldwin.png";
import pimg5 from "../assets/img/Thumbnails/BusinessMan/Cary-Katz.png";
import pimg6 from "../assets/img/Thumbnails/BusinessMan/Liang-Yu.png";
import pimg7 from "../assets/img/Thumbnails/BusinessMan/Ivan-Leow.png";
import pimg8 from "../assets/img/Thumbnails/BusinessMan/Rob-Yong.png";
import pimg9 from "../assets/img/Thumbnails/BusinessMan/Alfred-Decarolis.png";
import pimg10 from "../assets/img/Thumbnails/BusinessMan/ChinWei-Lim.png";
import pimg11 from "../assets/img/Thumbnails/BusinessMan/ChowHing-Yaung.png";
import pimg12 from "../assets/img/Thumbnails/BusinessMan/Pat-Madden.png";
import pimg13 from "../assets/img/Thumbnails/BusinessMan/Talal-Shakerchi.png";
import pimg14 from "../assets/img/Thumbnails/BusinessMan/Sosia-Jiang.png";
import pimg15 from "../assets/img/Thumbnails/BusinessMan/Wang-Qiang.png";
import pimg16 from "../assets/img/Thumbnails/BusinessMan/Aaron-Zhang.png";
import pimg17 from "../assets/img/Thumbnails/BusinessMan/Antanas-Gouga.png";
import pimg18 from "../assets/img/Thumbnails/BusinessMan/Leon-Tsoukernick.png";
import pimg19 from "../assets/img/Thumbnails/BusinessMan/Orpen-Kisacikoglu.png";
import pimg20 from "../assets/img/Thumbnails/BusinessMan/Ferdinand-Putra.png";
import pimg21 from "../assets/img/Thumbnails/BusinessMan/Andrew-Pantling.png";
import pimg22 from "../assets/img/Thumbnails/BusinessMan/Bill-Perkins.png";
import pimg23 from "../assets/img/Thumbnails/BusinessMan/Rick-Solomon.png";
import pimg24 from "../assets/img/Thumbnails/BusinessMan/Winfred-Yu.png";
import pimg25 from "../assets/img/Thumbnails/BusinessMan/Ben-Wu.png";
import pimg26 from "../assets/img/Thumbnails/BusinessMan/Haralabos-Voulgaris.png";

// Thumbnails for players
import pimg27 from "../assets/img/Thumbnails/Pros/Tom-Dwan.png";
import pimg28 from "../assets/img/Thumbnails/Pros/Dan-Cates.png";
import pimg29 from "../assets/img/Thumbnails/Pros/David-Peters.png";
import pimg30 from "../assets/img/Thumbnails/Pros/Rui-Cao.png";
import pimg31 from "../assets/img/Thumbnails/Pros/Jason-Koon.png";
import pimg32 from "../assets/img/Thumbnails/Pros/Bryn-Kenney.png";
import pimg33 from "../assets/img/Thumbnails/Pros/Mikita-Badziakouski.png";
import pimg34 from "../assets/img/Thumbnails/Pros/Timofey-Kuznetsov.png";
import pimg35 from "../assets/img/Thumbnails/Pros/Sam-Trickett.png";
import pimg36 from "../assets/img/Thumbnails/Pros/Stephen-Chidwick.png";
import pimg37 from "../assets/img/Thumbnails/Pros/Waileong-Chan.png";
import pimg38 from "../assets/img/Thumbnails/Pros/Cristoph-Vogelsang.png";
import pimg39 from "../assets/img/Thumbnails/Pros/Nick-Petrangelo.png";
import pimg40 from "../assets/img/Thumbnails/Pros/Igor-Kurganov.png";
import pimg41 from "../assets/img/Thumbnails/Pros/Sam-Greenwood.png";
import pimg42 from "../assets/img/Thumbnails/Pros/Elton-Tsang.png";
import pimg43 from "../assets/img/Thumbnails/Pros/Tan-Xuan.png";
import pimg44 from "../assets/img/Thumbnails/Pros/Fedor-Holz.png";
import pimg45 from "../assets/img/Thumbnails/Pros/Martin-Kabrel.png";
import pimg46 from "../assets/img/Thumbnails/Pros/Matthias-Eibinger.png";
import pimg47 from "../assets/img/Thumbnails/Pros/Justin-Bonomo.png";
import pimg48 from "../assets/img/Thumbnails/Pros/Andrew-Robl.png";
import pimg49 from "../assets/img/Thumbnails/Pros/Dan-Smith.png";
import pimg50 from "../assets/img/Thumbnails/Pros/Vivek-Raj.png";
import pimg51 from "../assets/img/Thumbnails/Pros/Danny-Tang.png";
import pimg52 from "../assets/img/Thumbnails/Pros/Michael-Soyza.png";
import pimg53 from "../assets/img/Thumbnails/Pros/Timothy-Adams.png";

// Country Icons
import Austria from "../assets/img/countries/austria.png";
import Belarus from "../assets/img/countries/belarus.png";
import Canada from "../assets/img/countries/canada.png";
import China from "../assets/img/countries/china.png";
import Czech from "../assets/img/countries/czech-republic.png";
import England from "../assets/img/countries/england.png";
import France from "../assets/img/countries/france.png";
import Germany from "../assets/img/countries/germany.png";
import Greece from "../assets/img/countries/greece.png";
import HongKong from "../assets/img/countries/hong-kong.png";
import India from "../assets/img/countries/india.png";
import Indonesia from "../assets/img/countries/indonesia.png";
import Malaysia from "../assets/img/countries/malaysia.png";
import MarshallIsland from "../assets/img/countries/marshall-island.png";
import NewZealand from "../assets/img/countries/new-zealand.png";
import Russia from "../assets/img/countries/russia.png";
import Turkey from "../assets/img/countries/turkey.png";
import USA from "../assets/img/countries/united-states-of-america.png";

export const Flags = [
  Austria, //0
  Belarus, //1
  Canada, //2
  China, //3
  Czech, //4
  England, //5
  France, //6
  Germany, //7
  Greece, //8
  HongKong, //9
  India, //10
  Indonesia, //11
  Malaysia, //12
  MarshallIsland, //13
  NewZealand, //14
  Russia, //15
  Turkey, //16
  USA, //17
];

export const Cdata = [Cimg5, Cimg6, Cimg7, Cimg8, Cimg9, Cimg10];

export const Pimages = [
  pimg0,
  pimg1,
  pimg2,
  pimg3,
  pimg4,
  pimg5,
  pimg6,
  pimg7,
  pimg8,
  pimg9,
  pimg10,
  pimg11,
  pimg12,
  pimg13,
  pimg14,
  pimg15,
  pimg16,
  pimg17,
  pimg18,
  pimg19,
  pimg20,
  pimg21,
  pimg22,
  pimg23,
  pimg24,
  pimg25,
  pimg26,
  pimg27,
  pimg28,
  pimg29,
  pimg30,
  pimg31,
  pimg32,
  pimg33,
  pimg34,
  pimg35,
  pimg36,
  pimg37,
  pimg38,
  pimg39,
  pimg40,
  pimg41,
  pimg42,
  pimg43,
  pimg44,
  pimg45,
  pimg46,
  pimg47,
  pimg48,
  pimg49,
  pimg50,
  pimg51,
  pimg52,
  pimg53,
];

// export const bmiamges =[bman,bman1, bman2,bman3, bman4, bman5, bman6, bman7, bman8, bman9, bman10, bman11, bman12, bman13, bman14, bman15, bman16, bman17, bman18, bman19,bman20, bman21, bman22, bman23, bman24, bman25, bman26]
