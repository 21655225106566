import React, { Component } from "react";
import { Col, Container, Row } from "reactstrap";
import Modal1 from "../assets/img/Image gallery element.png";
import Modal2 from "../assets/img/Image gallery element(1).png";
import Modal3 from "../assets/img/Image gallery element(2).png";
import Modal5 from "../assets/img/Image gallery element(4).png";
import Modal8 from "../assets/img/Rectangle 53(1).png";
import Modal10 from "../assets/img/Rectangle 53(3).png";
import Modal11 from "../assets/img/Rectangle 53(4).png";
import Modal12 from "../assets/img/Rectangle 53(5).png";
import Sponsors from "../components/Sponsors";

import GalleryImage from "./GalleryImage";

class Images extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      activeimg: null,
      isOpen: false,
    };
    this.toggle = this.toggle.bind(this);
  }
  toggle(img) {
    this.setState({
      modal: !this.state.modal,
      activeimg: img,
    });
  }
  render() {
    return (
      <div>
        <Sponsors />
        <Container className="col-lg-6 font-family-1">
          <div
            style={{
              paddingTop: "20px",
              margin: "auto",
              display: "flex",
              justifyContent: "center",
              position: "relative",
            }}
          >
            <hr
              id="gallery-anchor"
              style={{
                position: "absolute",
                top: "-180px",
                left: "0",
              }}
            />
            <h2
              style={{
                paddingBottom: "10px",
                borderBottom: "1px solid",
                textAlign: "center",
              }}
            >
              GALLERY
            </h2>
          </div>

          {/* <Container className="col-lg-8"> */}
          <Row lg="4" xs="2" style={{ margin: "50px 0px 30px 0px" }}>
            <Col className="imgGlry">
              <GalleryImage galleryUrl={Modal1} />
            </Col>

            <Col className="imgGlry">
              <GalleryImage galleryUrl={Modal2} />
            </Col>

            <Col className="imgGlry">
              <GalleryImage galleryUrl={Modal3} />
            </Col>

            <Col className="imgGlry">
              <GalleryImage galleryUrl={Modal5} />
            </Col>

            <Col className="imgGlry">
              <GalleryImage galleryUrl={Modal8} />
            </Col>

            <Col className="imgGlry">
              <GalleryImage galleryUrl={Modal10} />
            </Col>

            <Col className="imgGlry">
              <GalleryImage galleryUrl={Modal11} />
            </Col>

            <Col className="imgGlry">
              <GalleryImage galleryUrl={Modal12} />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
export default Images;
