import React, { Component } from "react";
import {
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import logo from "../../assets/img/logo.png";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { Link } from "react-router-dom";

export class Footer extends Component {
  render() {
    return (
      <Container
        fluid
        className="font-family-1"
        style={{ backgroundColor: "#181818", padding: "50px 0px" }}
      >
        <Row className="justify-content-center mb-5">
          <Col lg="5" xs="12" style={{ padding: "0px 50px 20px 50px" }}>
            <div>
              <h3>
                SUBSCRIBE TO OUR <br /> NEWSLETTER
              </h3>
              <Form className="d-lg-flex align-items-center mt-4">
                <FormGroup
                  className="mb-2 font-family-2 mb-lg-0"
                  style={{ width: "100%", marginRight: "15px" }}
                >
                  <Input
                    type="email"
                    name="email"
                    id="exampleEmail"
                    placeholder="Email Address"
                    className="bg-white"
                  />
                </FormGroup>{" "}
                <Button className="fbtn py-2 ">Subscribe</Button>
              </Form>
            </div>
          </Col>
          <Col lg="7" xs="12" style={{ padding: "0px 50px" }}>
            <div>
              <h3>FOLLOW US ON</h3>
              <ul
                className="p-0 pt-3 d-flex"
                style={{ justifyContent: "space-between" }}
              >
                <a href="https://facebook.com/tritonpoker" target="_blank">
                  <i className="fab fa-facebook fa-3x text-white"></i>
                </a>
                <a href="https://twitter.com/tritonpoker" target="_blank">
                  <i className="fab fa-twitter fa-3x text-white"></i>
                </a>
                <a
                  href="https://instagram.com/tritonpokerseries"
                  target="_blank"
                >
                  <i className="fab fa-instagram fa-3x text-white"></i>
                </a>
                <a href="https://www.youtube.com/c/TritonPoker" target="_blank">
                  <i className="fab fa-youtube fa-3x text-white"></i>
                </a>
              </ul>
              <h3 className="pt-3 pb-3">GOT A QUESTION?</h3>
              <Row
                lg="3"
                xs="1"
                className="d-flex ml-1"
                style={{ justifyContent: "space-between" }}
              >
                <div className="mb-2">
                  <h6>FOR MORE INFO</h6>
                  <Link to="/" className="link font-family-2">
                    info@triton-series.com
                  </Link>
                </div>
                <div className="mb-2">
                  <h6>JOIN OUR NEXT EVENT</h6>
                  <Link to="/" className="link font-family-2">
                    register@triton-series.com
                  </Link>
                </div>
                <div className="mb-2">
                  <h6>WANT TO BE A SPONSOR?</h6>
                  <Link to="/" className="link font-family-2">
                    sponsors@triton-series.com
                  </Link>
                </div>
              </Row>
              <div className="pt-4 pb-4">
                <Link to="/" className="link font-family-2">
                  <i
                    className="fa fa-map-marker-alt text-site-secondary"
                    style={{ paddingRight: "10px" }}
                  ></i>
                  85, Fenlex House, St. John Street, Valleta, VLT1165, Malta
                </Link>
              </div>
            </div>
          </Col>
        </Row>
        <div
          className="d-flex font-family-2"
          style={{ padding: "0px 50px", justifyContent: "space-between" }}
        >
          <a href="https://triton-series.com/privacy-policy/" className="link">
            Privacy Policy
          </a>
          <a
            href="https://triton-series.com/terms-and-conditions/"
            className="link"
          >
            Terms & Conditions
          </a>
          <a href="https://triton-series.com/" className="link">
            Back to Main Site
          </a>
        </div>
      </Container>
    );
  }
}
export default Footer;
