import React from "react";

const SliderCharityItem = (props) => {
  return (
    <>
      <div className="text-center beneficiary">
        <img
          className="pt-2 pb-2 m-auto charity-logo"
          src={props.charityLogo}
          alt="logo"
        />
        <p>{props.charityName}</p>
      </div>
    </>
  );
};

export default SliderCharityItem;
