import React from "react";

const GalleryImage = (props) => {
  return (
    <>
      <img src={props.galleryUrl} className="img-fluid" alt="imghe" />
    </>
  );
};

export default GalleryImage;
