import React from "react";

const SponsorItem = (props) => {
  return (
    <>
      <a href={props.sponsorUrl} target="_blank">
        <img src={props.sponsorLogo} alt={`${props.sponsorName} logo`} />
      </a>
    </>
  );
};

export default SponsorItem;
