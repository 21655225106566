import bman from "./assets/img/Business Man/template_business-man__0000_Layer-2.png";
import bman1 from "./assets/img/Business Man/template_business-man__0001_26---BenWu.png";
import bman2 from "./assets/img/Business Man/template_business-man__0002_25---WinfredYu.png";
import bman3 from "./assets/img/Business Man/template_business-man__0003_24---RickSolo.png";
import bman4 from "./assets/img/Business Man/template_business-man__0004_23---BillPerk.png";
import bman5 from "./assets/img/Business Man/template_business-man__0005_22---AndrewPant.png";
import bman6 from "./assets/img/Business Man/template_business-man__0006_21---ferdinandPut.png";
import bman7 from "./assets/img/Business Man/template_business-man__0007_20---orpenKisa.png";
import bman8 from "./assets/img/Business Man/template_business-man__0008_19---Leon.png";
import bman9 from "./assets/img/Business Man/template_business-man__0009_18---Tony-Gouga.png";
import bman10 from "./assets/img/Business Man/template_business-man__0010_17---AaronZhang.png";
import bman11 from "./assets/img/Business Man/template_business-man__0011_16---WangQiang.png";
import bman12 from "./assets/img/Business Man/template_business-man__0012_15---SosiaJia.png";
import bman13 from "./assets/img/Business Man/template_business-man__0013_14---TalalSh.png";
import bman14 from "./assets/img/Business Man/template_business-man__0014_13---PatMadden.png";
import bman15 from "./assets/img/Business Man/template_business-man__0015_12---ChowHingY.png";
import bman16 from "./assets/img/Business Man/template_business-man__0016_11---ChinWeiLim.png";
import bman17 from "./assets/img/Business Man/template_business-man__0017_10---AlfredDec.png";
import bman18 from "./assets/img/Business Man/template_business-man__0018_9---RobYong.png";
import bman19 from "./assets/img/Business Man/template_business-man__0019_8---ivanLeaw.png";
import bman20 from "./assets/img/Business Man/template_business-man__0020_7---yuLiang.png";
import bman21 from "./assets/img/Business Man/template_business-man__0021_6---caryKatz.png";
import bman22 from "./assets/img/Business Man/template_business-man__0022_5---bobbyBaldwin.png";
import bman23 from "./assets/img/Business Man/template_business-man__0023_4---waikinyong.png";
import bman24 from "./assets/img/Business Man/template_business-man__0024_3---stanley-choii.png";
import bman25 from "./assets/img/Business Man/template_business-man__0025_2---richard-yong.png";
import bman26 from "./assets/img/Business Man/template_business-man__0026_1---paulphua.png";

import pman from "./assets/img/Players/template_pro__0000_27---TimothyAdams.png";
import pman1 from "./assets/img/Players/template_pro__0001_26---MichealSoyza.png";
import pman2 from "./assets/img/Players/template_pro__0002_25---DannyTang.png";
import pman3 from "./assets/img/Players/template_pro__0003_24---VivekRaj.png";
import pman4 from "./assets/img/Players/template_pro__0004_23---DanSmith.png";
import pman5 from "./assets/img/Players/template_pro__0005_22---AndrewRobl.png";
import pman6 from "./assets/img/Players/template_pro__0006_21---Justin-Bonomo.png";
import pman7 from "./assets/img/Players/template_pro__0007_20---MatthiasEibinger.png";
import pman8 from "./assets/img/Players/template_pro__0008_19---MartinKabrel.png";
import pman9 from "./assets/img/Players/template_pro__0009_18---FedorHolz.png";
import pman10 from "./assets/img/Players/template_pro__0010_17---TanXuan.png";
import pman11 from "./assets/img/Players/template_pro__0011_16---EltonTsang.png";
import pman12 from "./assets/img/Players/template_pro__0012_15---SamGreenwood.png";
import pman13 from "./assets/img/Players/template_pro__0013_14---IgorKu.png";
import pman14 from "./assets/img/Players/template_pro__0014_13---NickPat.png";
import pman15 from "./assets/img/Players/template_pro__0015_12---ChristovVol.png";
import pman16 from "./assets/img/Players/template_pro__0016_11---WailoengChan.png";
import pman17 from "./assets/img/Players/template_pro__0017_10---StephenChid.png";
import pman18 from "./assets/img/Players/template_pro__0018_9---SamTrinket.png";
import pman19 from "./assets/img/Players/template_pro__0019_8---TimofeyKuz.png";
import pman20 from "./assets/img/Players/template_pro__0020_7---MikitaBadz.png";
import pman21 from "./assets/img/Players/template_pro__0021_6---BrynKenney.png";
import pman22 from "./assets/img/Players/template_pro__0022_5---JasonKoon.png";
import pman23 from "./assets/img/Players/template_pro__0023_4---ruiCao.png";
import pman24 from "./assets/img/Players/template_pro__0024_3---DavidPete.png";
import pman25 from "./assets/img/Players/template_pro__0025_2---DanCates.png";
import pman26 from "./assets/img/Players/template_pro__0026_1---TomDwa.png";

import { Pimages } from "./components/Cdata";
import { Flags } from "./components/Cdata";

export const info = [
  {
    player: {
      name: "Paul",
      lastname: "Phua",
      img: bman26,
      country: "Malaysia",
      countryIcon: Flags[12],
      thumbnail: Pimages[0],
    },
    opponent: {
      name: "Tom",
      lastname: "Dwan",
      img: pman26,
      country: "USA",
      countryIcon: Flags[17],
      thumbnail: Pimages[27],
    },
  },
  {
    player: {
      name: "Richard",
      lastname: "Yong",
      img: bman25,
      country: "Malaysia",
      countryIcon: Flags[12],
      thumbnail: Pimages[1],
    },
    opponent: {
      name: "Daniel",
      lastname: "Cates",
      img: pman25,
      country: "USA",
      countryIcon: Flags[17],
      thumbnail: Pimages[28],
    },
  },

  //
  {
    player: {
      name: "Stanley",
      lastname: "Choi",
      img: bman24,
      country: "Hong Kong",
      countryIcon: Flags[9],
      thumbnail: Pimages[2],
    },
    opponent: {
      name: "David",
      lastname: "Peters",
      img: pman24,
      country: "USA",
      countryIcon: Flags[17],
      thumbnail: Pimages[29],
    },
  },
  {
    player: {
      name: "Wai Kin",
      lastname: "Yong",
      img: bman23,
      country: "Malaysia",
      countryIcon: Flags[12],
      thumbnail: Pimages[3],
    },
    opponent: {
      name: "Rui",
      lastname: "Cao",
      img: pman23,
      country: "France",
      countryIcon: Flags[6],
      thumbnail: Pimages[30],
    },
  },
  {
    player: {
      name: "Bobby",
      lastname: "Baldwin",
      img: bman22,
      country: "USA",
      countryIcon: Flags[17],
      thumbnail: Pimages[4],
    },
    opponent: {
      name: "Jason",
      lastname: "Koon",
      img: pman22,
      country: "USA",
      countryIcon: Flags[17],
      thumbnail: Pimages[31],
    },
  },
  {
    player: {
      name: "Cary",
      lastname: "Katz",
      img: bman21,
      country: "USA",
      countryIcon: Flags[17],
      thumbnail: Pimages[5],
    },
    opponent: {
      name: "Bryn",
      lastname: "Kenney",
      img: pman21,
      country: "USA",
      countryIcon: Flags[17],
      thumbnail: Pimages[32],
    },
  },
  {
    player: {
      name: "Liang",
      lastname: "Yu",
      img: bman20,
      country: "Marshall Island",
      countryIcon: Flags[13],
      thumbnail: Pimages[6],
    },
    opponent: {
      name: "Mikita",
      lastname: "Badziakouski",
      img: pman20,
      country: "Belarus",
      countryIcon: Flags[1],
      thumbnail: Pimages[33],
    },
  },
  {
    player: {
      name: "Ivan",
      lastname: "Leow",
      img: bman19,
      country: "Malaysia",
      countryIcon: Flags[12],
      thumbnail: Pimages[7],
    },
    opponent: {
      name: "Timofey",
      lastname: "Kuznetsov",
      img: pman19,
      country: "Russia",
      countryIcon: Flags[15],
      thumbnail: Pimages[34],
    },
  },
  {
    player: {
      name: "Rob",
      lastname: "Yong",
      img: bman18,
      country: "England",
      countryIcon: Flags[5],
      thumbnail: Pimages[8],
    },
    opponent: {
      name: "Sam",
      lastname: "Trickett",
      img: pman18,
      country: "England",
      countryIcon: Flags[5],
      thumbnail: Pimages[35],
    },
  },
  {
    player: {
      name: "Alfred",
      lastname: "Decarolis",
      img: bman17,
      country: "USA",
      countryIcon: Flags[17],
      thumbnail: Pimages[9],
    },
    opponent: {
      name: "Stephen",
      lastname: "Chidwick",
      img: pman17,
      country: "England",
      countryIcon: Flags[5],
      thumbnail: Pimages[36],
    },
  },
  {
    player: {
      name: "Chin Wei",
      lastname: "Lim",
      img: bman16,
      country: "Malaysia",
      countryIcon: Flags[12],
      thumbnail: Pimages[10],
    },
    opponent: {
      name: "Wai Leong",
      lastname: "Chan",
      img: pman16,
      country: "Malaysia",
      countryIcon: Flags[12],
      thumbnail: Pimages[37],
    },
  },
  {
    player: {
      name: "Chow Hing",
      lastname: "Yaung",
      img: bman15,
      country: "Malaysia",
      countryIcon: Flags[12],
      thumbnail: Pimages[11],
    },
    opponent: {
      name: "Cristoph",
      lastname: "Vogelsang",
      img: pman15,
      country: "Germany",
      countryIcon: Flags[7],
      thumbnail: Pimages[38],
    },
  },
  {
    player: {
      name: "Pat",
      lastname: "Madden",
      img: bman14,
      country: "USA",
      countryIcon: Flags[17],
      thumbnail: Pimages[12],
    },
    opponent: {
      name: "Nick",
      lastname: "Petrangelo",
      img: pman14,
      country: "USA",
      countryIcon: Flags[17],
      thumbnail: Pimages[39],
    },
  },
  {
    player: {
      name: "Talal",
      lastname: "Shakerchi",
      img: bman13,
      country: "England",
      countryIcon: Flags[5],
      thumbnail: Pimages[13],
    },
    opponent: {
      name: "Igor",
      lastname: "Kurganov",
      img: pman13,
      country: "Russia",
      countryIcon: Flags[15],
      thumbnail: Pimages[40],
    },
  },
  {
    player: {
      name: "Sosia",
      lastname: "Jiang",
      img: bman12,
      country: "New Zealand",
      countryIcon: Flags[14],
      thumbnail: Pimages[14],
    },
    opponent: {
      name: "Sam",
      lastname: "Greenwood",
      img: pman12,
      country: "Canada",
      countryIcon: Flags[2],
      thumbnail: Pimages[41],
    },
  },
  {
    player: {
      name: "Qiang",
      lastname: "Wang",
      img: bman11,
      country: "China",
      countryIcon: Flags[3],
      thumbnail: Pimages[15],
    },
    opponent: {
      name: "Elton",
      lastname: "Tsang",
      img: pman11,
      country: "Hong Kong",
      countryIcon: Flags[9],
      thumbnail: Pimages[42],
    },
  },
  {
    player: {
      name: "Shu Nu",
      lastname: "Zang",
      img: bman10,
      country: "China",
      countryIcon: Flags[3],
      thumbnail: Pimages[16],
    },
    opponent: {
      name: "Tan",
      lastname: "Xuan",
      img: pman10,
      country: "China",
      countryIcon: Flags[3],
      thumbnail: Pimages[43],
    },
  },
  {
    player: {
      name: "Antanas",
      lastname: "Guoga",
      img: bman9,
      country: "Greece",
      countryIcon: Flags[8],
      thumbnail: Pimages[17],
    },
    opponent: {
      name: "Fedor",
      lastname: "Holz",
      img: pman9,
      country: "Germany",
      countryIcon: Flags[7],
      thumbnail: Pimages[44],
    },
  },
  {
    player: {
      name: "Leon",
      lastname: "Tsoukernik",
      img: bman8,
      country: "Czech Republic",
      countryIcon: Flags[4],
      thumbnail: Pimages[18],
    },
    opponent: {
      name: "Martin",
      lastname: "Kabrhel",
      img: pman8,
      country: "Czech Republic",
      countryIcon: Flags[4],
      thumbnail: Pimages[45],
    },
  },
  {
    player: {
      name: "Orpen",
      lastname: "Kisacikoglu",
      img: bman7,
      country: "Turkey",
      countryIcon: Flags[16],
      thumbnail: Pimages[19],
    },
    opponent: {
      name: "Matthias",
      lastname: "Eibinger",
      img: pman7,
      country: "Austria",
      countryIcon: Flags[0],
      thumbnail: Pimages[46],
    },
  },
  {
    player: {
      name: "Ferdinand",
      lastname: "Putra",
      img: bman6,
      country: "Indonesia",
      countryIcon: Flags[11],
      thumbnail: Pimages[20],
    },
    opponent: {
      name: "Justin",
      lastname: "Bonomo",
      img: pman6,
      country: "USA",
      countryIcon: Flags[17],
      thumbnail: Pimages[47],
    },
  },
  {
    player: {
      name: "Andrew",
      lastname: "Pantling",
      img: bman5,
      country: "Canada",
      countryIcon: Flags[2],
      thumbnail: Pimages[21],
    },
    opponent: {
      name: "Andrew",
      lastname: "Robl",
      img: pman5,
      country: "USA",
      countryIcon: Flags[17],
      thumbnail: Pimages[48],
    },
  },
  {
    player: {
      name: "Bill",
      lastname: "Perkins",
      img: bman4,
      country: "USA",
      countryIcon: Flags[17],
      thumbnail: Pimages[22],
    },
    opponent: {
      name: "Dan",
      lastname: "Smith",
      img: pman4,
      country: "USA",
      countryIcon: Flags[17],
      thumbnail: Pimages[49],
    },
  },
  {
    player: {
      name: "Rick",
      lastname: "Salomon",
      img: bman3,
      country: "USA",
      countryIcon: Flags[17],
      thumbnail: Pimages[23],
    },
    opponent: {
      name: "Vivek",
      lastname: "Rajkumar",
      img: pman3,
      country: "India",
      countryIcon: Flags[10],
      thumbnail: Pimages[50],
    },
  },
  {
    player: {
      name: "Winfred",
      lastname: "Yu",
      img: bman2,
      country: "Hong Kong",
      countryIcon: Flags[9],
      thumbnail: Pimages[24],
    },
    opponent: {
      name: "Danny",
      lastname: "Tang",
      img: pman2,
      country: "Hong Kong",
      countryIcon: Flags[9],
      thumbnail: Pimages[51],
    },
  },
  {
    player: {
      name: "Ben",
      lastname: "Wu",
      img: bman1,
      country: "USA",
      countryIcon: Flags[17],
      thumbnail: Pimages[25],
    },
    opponent: {
      name: "Michael",
      lastname: "Soyza",
      img: pman1,
      country: "Malaysia",
      countryIcon: Flags[12],
      thumbnail: Pimages[52],
    },
  },
  {
    player: {
      name: "Haralabos",
      lastname: "Voulgaris",
      img: bman,
      country: "Canada",
      countryIcon: Flags[2],
      thumbnail: Pimages[26],
    },
    opponent: {
      name: "Timothy",
      lastname: "Adams",
      img: pman,
      country: "Canada",
      countryIcon: Flags[2],
      thumbnail: Pimages[53],
    },
  },
];
