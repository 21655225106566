import { Container, Row, Col } from "reactstrap";
import React, { useState } from "react";
import { info } from "../info";
import Review from "../components/Review";
import Slider from "react-slick";
import SliderCharityItem from "./SliderCharityItem";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import reglogo from "../assets/img/reg.png";
import onedroplogo from "../assets/img/onedrop.png";
import caritaslogo from "../assets/img/caritasmacau.png";
import wecaretaiwanlogo from "../assets/img/wecaretaiwan.png";
import malaysianredcrescentlogo from "../assets/img/malaysianredcrescent.png";
import sccslogo from "../assets/img/SCCS.png";
import caringforchildrenlogo from "../assets/img/caringforchildren.png";
import creditonelogo from "../assets/img/creditone.png";
import healthyhklogo from "../assets/img/healthyhongkong.png";
import overcomingmslogo from "../assets/img/overcomeMS.png";

const Previousbtn = (props) => {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <i className="fa fa-angle-left"></i>
    </div>
  );
};
const Nextbtn = (props) => {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <i className="ml-2 fa fa-angle-right"></i>
    </div>
  );
};

const Events = () => {
  let p1 = { ...info[0].player };
  let p2 = { ...info[0].opponent };
  let [pl, setPl] = useState(p1);
  let [pr, setPr] = useState(p2);
  let setPlayers = (pl, pr) => {
    setPl(pl);
    setPr(pr);
  };
  const settings = {
    nextArrow: <Nextbtn />,
    prevArrow: <Previousbtn />,
    slidesToShow: 5,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };
  console.warn(info);
  return (
    <>
      <div id="review" className="element">
        <Review />
      </div>
      <div className="bg_image1 font-family-1">
        <div className="bg_image2">
          <div className="bg_image3"></div>
          <div
            style={{
              paddingTop: "0",
              paddingBottom: "60px",
              margin: "auto",
              display: "flex",
              textAlign: "center",
              justifyContent: "center",
            }}
          >
            <h2
              style={{
                paddingBottom: "10px",
                borderBottom: "1px solid",
              }}
            >
              PARTICIPANTS
            </h2>
          </div>
          <div className="participants-text d-none d-md-flex">
            <div className="participant-detail text-right">
              <h1>{pl.name}</h1>
              <h1>{pl.lastname}</h1>
              <small className="font-family-2" style={{ fontSize: "14px" }}>
                {pl.country}{" "}
                <img src={pl.countryIcon} style={{ maxWidth: "20px" }} />
              </small>
            </div>
            <small class="text-site-secondary font-family-2">Invites</small>
            <div className="participant-detail text-left">
              <h1>{pr.name}</h1>
              <h1>{pr.lastname}</h1>
              <small className="font-family-2" style={{ fontSize: "14px" }}>
                <img src={pr.countryIcon} style={{ maxWidth: "20px" }} />{" "}
                {pr.country}
              </small>
            </div>
          </div>
          <div className="participants-text d-flex d-md-none">
            <div className="participant-detail text-right">
              <h1>{pl.name}</h1>
              <h1>{pl.lastname}</h1>
              <small
                className="font-family-2 smalltext"
                style={{ fontSize: "14px" }}
              >
                {pl.country}
                <img src={pl.countryIcon} style={{ maxWidth: "20px" }} />
              </small>
            </div>
            <small class="text-site-secondary font-family-2 smalltext">
              Invites
            </small>
            <div className="participant-detail text-left">
              <h1>{pr.name}</h1>
              <h1>{pr.lastname}</h1>
              <small
                className="font-family-2 smalltext"
                style={{ fontSize: "14px" }}
              >
                <img src={pr.countryIcon} style={{ maxWidth: "20px" }} />
                {pr.country}
              </small>
            </div>
          </div>
          <Row lg="2" xs="2">
            <Col className="d-flex justify-content-center">
              <img
                src={pl.img}
                class="participant-left"
                style={{
                  width: "-webkit-fill-available",
                  marginLeft: "-60px",
                  marginTop: "-80px",
                  maxWidth: "500px",
                }}
                alt="logo"
              />
            </Col>
            <Col className="d-flex align-items-end justify-content-center">
              <img
                src={pr.img}
                class="participant-right"
                style={{
                  width: "-webkit-fill-available",
                  marginRight: "-60px",
                  marginTop: "-80px",
                  maxWidth: "500px",
                }}
                alt="logo"
              />
            </Col>
          </Row>
        </div>
      </div>
      <div className="bg_image4 d-lg-block d-md-block participants d-none">
        <div style={{ backgroundColor: "#352e20" }}>
          <Container>
            <Row className="d-flex justify-content-center">
              <Col lg="1" className="text-white mt-auto mb-auto">
                <p>Businessmen</p>
              </Col>
              <Col lg="8">
                <Slider
                  prevArrow={<Previousbtn />}
                  nextArrow={<Nextbtn />}
                  slidesToShow={10}
                  slidesToScroll={6}
                >
                  {info.map((item1, idx) => {
                    // console.log(item1);
                    return (
                      <>
                        <Card1
                          item1={item1}
                          setPlayers={() =>
                            setPlayers(item1.player, item1.opponent)
                          }
                        />
                      </>
                    );
                  })}
                </Slider>
              </Col>
            </Row>
          </Container>
        </div>
        <div
          style={{
            backgroundColor: "#796741",
            borderBottom: "solid 1px #e9bc66",
          }}
        >
          <Container>
            <Row className="d-flex justify-content-center">
              <Col lg="1" className="text-white mt-auto mb-auto">
                <p>Pros</p>
              </Col>
              <Col lg="8">
                <Slider
                  prevArrow={<Previousbtn />}
                  nextArrow={<Nextbtn />}
                  slidesToShow={10}
                  slidesToScroll={6}
                >
                  {info.map((item1) => (
                    <>
                      <Card2
                        item1={item1}
                        setPlayers={() =>
                          setPlayers(item1.player, item1.opponent)
                        }
                      />
                    </>
                  ))}
                </Slider>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <div className="bg_image4 participants d-block d-lg-none d-md-none">
        <div style={{ backgroundColor: "#352e20" }}>
          <Row className="d-flex justify-content-center">
            <Col xs="12" className="text-white m-auto pl-5">
              <p>Businessmen</p>
            </Col>
            <Col xs="10">
              <Slider
                prevArrow={<Previousbtn />}
                nextArrow={<Nextbtn />}
                slidesToShow={5}
                slidesToScroll={3}
              >
                {info.map((item1) => (
                  <>
                    <Card1
                      item1={item1}
                      setPlayers={() =>
                        setPlayers(item1.player, item1.opponent)
                      }
                    />
                  </>
                ))}
              </Slider>
            </Col>
          </Row>
        </div>
        <div
          style={{
            backgroundColor: "#796741",
            borderBottom: "solid 1px #e9bc66",
          }}
        >
          <Row className="d-flex justify-content-center">
            <Col ls="12" className="text-white pl-5">
              <p>Pros</p>
            </Col>
            <Col xs="10">
              <Slider
                prevArrow={<Previousbtn />}
                nextArrow={<Nextbtn />}
                slidesToShow={5}
                slidesToScroll={3}
              >
                {info.map((item1) => (
                  <>
                    <Card2
                      item1={item1}
                      setPlayers={() =>
                        setPlayers(item1.player, item1.opponent)
                      }
                    />
                  </>
                ))}
              </Slider>
            </Col>
          </Row>
        </div>
      </div>
      <div className="bef">
        <div
          style={{
            paddingTop: "60px",
            margin: "auto",
            display: "flex",
            justifyContent: "center",
            position: "relative",
          }}
        >
          <hr
            id="charity-anchor"
            style={{
              position: "absolute",
              top: "-160px",
              left: "0",
            }}
          />
          <h2
            className="font-family-1"
            style={{
              paddingBottom: "10px",
              borderBottom: "1px solid",
              marginBottom: "50px",
            }}
          >
            BENEFICIARIES
          </h2>
        </div>
        <Slider {...settings} className="d-block charity-slider">
          <SliderCharityItem charityLogo={reglogo} charityName="REG" />
          <SliderCharityItem
            charityLogo={onedroplogo}
            charityName="The One Drop"
          />
          <SliderCharityItem
            charityLogo={caritaslogo}
            charityName="Caritas Macau"
          />
          <SliderCharityItem
            charityLogo={wecaretaiwanlogo}
            charityName="We Care Taiwan"
          />
          <SliderCharityItem
            charityLogo={malaysianredcrescentlogo}
            charityName="Malaysian Red Crescent"
          />
          <SliderCharityItem charityLogo={sccslogo} charityName="SCCS" />
          <SliderCharityItem
            charityLogo={caringforchildrenlogo}
            charityName="Caring for Children"
          />
          <SliderCharityItem
            charityLogo={creditonelogo}
            charityName="Credit One World"
          />
          <SliderCharityItem
            charityLogo={healthyhklogo}
            charityName="Healthy Hong Kong"
          />
          <SliderCharityItem
            charityLogo={overcomingmslogo}
            charityName="Overcoming MS"
          />
        </Slider>
      </div>
    </>
  );
};

const Card1 = ({ item1, setPlayers }) => {
  return (
    <>
      <div
        onClick={setPlayers}
        className="m-1 participant-wrapper  font-family-2"
      >
        <div className="participant-detail text-center text-white">
          <h6 class="text-capitalize font-weight-bold">
            {item1.player.name} &nbsp;
            {item1.player.lastname}
          </h6>
          <small>{item1.player.country}</small>
        </div>
        <img src={item1.player.thumbnail} alt="logo" />
      </div>
    </>
  );
};
const Card2 = ({ item1, setPlayers }) => {
  return (
    <>
      <div
        onClick={setPlayers}
        className="m-1 participant-wrapper  font-family-2"
      >
        <div className="participant-detail text-center text-white">
          <h6 class="text-capitalize font-weight-bold">
            {item1.opponent.name} &nbsp;
            {item1.opponent.lastname}
          </h6>
          <small>{item1.opponent.country}</small>
        </div>
        <img src={item1.opponent.thumbnail} alt="logo" />
      </div>
    </>
  );
};

export default Events;
