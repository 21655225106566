import Premiere from "../components/Premiere";
import Events from "../components/Events";
import Images from "../components/Images";
const Landing = (props) => {
  return (
    <>
      <div id="top" className="element">
        <Premiere />
      </div>
      <div id="events" className="element">
        <Events />
      </div>
      <div id="images" className="element">
        <Images />
      </div>
    </>
  );
};

export default Landing;
