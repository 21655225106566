import { Col, Row } from "reactstrap";
import React, { Component } from "react";
import RCup from "../assets/img/small trophy png.png";

export default class Review extends Component {
  render() {
    return (
      <div className="font-family-1">
        <Row className="bg_image">
          <div className="event-deets">
            <div className="event-content">
              <h2>A HELPING HAND FOR CHARITY</h2>
              <div>
                <div className="text-muted mb-4">
                  From August 1-3, 2019 <br />
                  at London Hilton on Park Lane
                </div>
                <p className="text-white">
                  Back in 2019, 54 participants battled in the biggest poker
                  tournament in history, the Triton Million - A Helping Hand for
                  Charity.
                  <br />
                  <br /> The buy-in to enter was a massive £1,050,000, with the
                  winner receiving a nice £19,000,000 payout. <br />
                  <br /> Previously only available on sports channels worldwide,
                  viewers can now enjoy all 10 episodes of the prestigious event
                  on the Triton Poker YouTube channel alongside poker legend
                  Daniel Negreanu and TV personality Ali Nejad as the
                  commentators. <br />
                  <br /> Each participant had to pay an extra £50,000 along with
                  their buy-ins, accumulating to an incredible £2.7million
                  raised for charities worldwide.
                </p>
              </div>
            </div>
          </div>
          <Col className="trophy" lg="7" md="6" xs="12">
            <a>
              <img src={RCup} style={{ width: "100%" }} alt="logo" />
            </a>
          </Col>
        </Row>
      </div>
    );
  }
}
