import React from "react";

const SliderVideo = (props) => {
  return (
    <>
      <div className="ep-slider-item">
        <iframe
          width="360"
          height="200"
          src={props.videoUrl}
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
    </>
  );
};

export default SliderVideo;
