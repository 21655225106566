import React, { useState } from "react";
// import './style.css';
import logo from "../../assets/img/logo1.png";
import {
  Container,
  Navbar,
  NavbarBrand,
  Nav,
  NavItem,
  Row,
  Col,
} from "reactstrap";
// import { NavLink } from 'react-router-dom';
import { Link } from "react-scroll";

const Header = (props) => {
  return (
    <Container
      fluid
      className="navbarbg pr-0 pl-0"
      style={{ position: "fixed" }}
    >
      <Row xs="1" lg="1" style={{ display: "contents" }}>
        <NavbarBrand href="/" className="d-flex justify-content-center mr-0">
          <Link spy={true} smooth={true} duration={500} to="top">
            <img src={logo} alt="logo" width="120px" />
          </Link>
        </NavbarBrand>
      </Row>

      <Navbar
        expand="md"
        className="font-family-2 d-flex justify-content-center pl-0 pr-0"
      >
        <Row>
          <Col xs="12" lg="8" style={{ display: "contents" }}>
            <Nav className="mr-auto">
              <NavItem>
                <Link
                  spy={true}
                  smooth={true}
                  duration={500}
                  to="episode"
                  className="navlink p-lg-5 p-3"
                  activeClass="navlink-active"
                >
                  Episodes
                </Link>
              </NavItem>
              <NavItem>
                <Link
                  spy={true}
                  smooth={true}
                  duration={500}
                  to="event-anchor"
                  className="navlink p-lg-5 p-3"
                  activeClass="navlink-active"
                >
                  Event
                </Link>
              </NavItem>
              <NavItem>
                <Link
                  spy={true}
                  smooth={true}
                  duration={500}
                  to="charity-anchor"
                  className="navlink p-lg-5 p-3"
                  activeClass="navlink-active"
                >
                  Charity
                </Link>
              </NavItem>
              <NavItem>
                <Link
                  spy={true}
                  smooth={true}
                  duration={500}
                  to="gallery-anchor"
                  className="navlink p-lg-5 p-3"
                  activeClass="navlink-active"
                >
                  Gallery
                </Link>
              </NavItem>
            </Nav>
          </Col>
        </Row>
      </Navbar>
    </Container>
  );
};

export default Header;
