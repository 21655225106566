import React from "react";
import { Col, Container, Row } from "reactstrap";
import PPLlogo from "../assets/img/partypokerlive.png";
import LesAlogo from "../assets/img/LesA-white.png";
import Stakelogo from "../assets/img/stake1.png";
import Krakenlogo from "../assets/img/krakenlogo.png";
import SponsorItem from "./SponsorItem";

const Sponsors = () => {
  return (
    <>
      <Container
        className="col-lg-6 font-family-1 sponsors"
        style={{ paddingBottom: "40px" }}
      >
        <div
          style={{
            paddingTop: "60px",
            paddingBottom: "40px",
            margin: "auto",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <h2
            style={{
              paddingBottom: "10px",
              borderBottom: "1px solid",
              textAlign: "center",
            }}
          >
            SPONSORS
          </h2>
        </div>
        <Row lg="4" xs="2">
          <Col>
            <SponsorItem
              sponsorUrl="https://partypokerlive.com/"
              sponsorLogo={PPLlogo}
              sponsorName="Partypoker live"
            />
          </Col>
          <Col>
            <SponsorItem
              sponsorUrl="https://www.lesambassadeurs.com/"
              sponsorLogo={LesAlogo}
              sponsorName="Les Ambassadeurs"
            />
          </Col>
          <Col>
            <SponsorItem
              sponsorUrl="https://click.triton-series.com/stake"
              sponsorLogo={Stakelogo}
              sponsorName="stake.com"
            />
          </Col>
          <Col>
            <SponsorItem
              sponsorUrl="https://click.triton-series.com/kraken"
              sponsorLogo={Krakenlogo}
              sponsorName="Kraken"
            />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Sponsors;
